import React from 'react';

import { getClickType } from '../../../sharedModules/getClickType';
import type { Deal } from '../../types/Deal';
import { ProductTypes } from '../../../types/ProductType';
import getPriceType from '../../utils/getPriceType';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';

import getMerchantLinkCTAtext from './getMerchantLinkCTAtext';
import styles from './styles/merchant-link-widget-item-modern.css';

interface MerchantLinkWidgetItemModernProps {
  deal: Deal;
  activeButton: number;
  setActiveButton: (buttonIndex: number) => void;
  index: number;
  transformToUppercase?: boolean;
}

const MerchantLinkWidgetItemModern: React.FC<MerchantLinkWidgetItemModernProps> = ({
  deal,
  activeButton,
  setActiveButton,
  index,
  transformToUppercase = false,
}) => {
  const {
    translate,
    showPrice,
    showPrimaryLabel,
    genericSharedComponents: { AffiliateLink, DisplayPrice },
    merchantlinkTextType,
    articleUrl,
    linkText,
  } = useHawkWidgetContext();
  if (!deal) {
    return null;
  }

  const handleMouseEnter = (buttonIndex: number): void => {
    setActiveButton(buttonIndex);
  };

  const isFallbackWithLinkText = linkText && deal.product_type === ProductTypes.fallback;

  return (
    <AffiliateLink
      deal={deal}
      className="merchantlink-modern-button"
      clickType={getClickType({
        link: deal.offer.link,
        articleUrl,
        productType: deal.product_type,
      })}
    >
      <div className={styles['wrapper']}>
        <div
          className={styles['modern-button']}
          data-last-hovered={activeButton === index}
          onMouseEnter={(): void => handleMouseEnter(index)}
          data-product-type={deal?.product_type}
        >
          <div>
            {deal?.offer?.price && showPrice === 'showPrice' && !isFallbackWithLinkText && (
              <>
                <DisplayPrice
                  deal={deal}
                  className="merchantlink-transparent-price"
                  type={getPriceType(deal)}
                  wrapInLink={false}
                />{' '}
              </>
            )}
            <span className={transformToUppercase && styles['uppercase']}>
              {getMerchantLinkCTAtext(deal, translate, merchantlinkTextType)}
            </span>
            <span className={styles['primary-label']}>
              {showPrimaryLabel &&
                deal?.offer?.display_primary_label &&
                deal.offer.display_primary_label}
            </span>
            {deal?.offer?.price && Number(deal.offer.price) > 0 && showPrice === 'stream' && (
              <>
                {' '}
                <DisplayPrice
                  deal={deal}
                  className="merchantlink-price"
                  type={getPriceType(deal)}
                  wrapInLink={false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </AffiliateLink>
  );
};

export default MerchantLinkWidgetItemModern;
