import { Site } from '../../core/modules/sites/types/Site';
import { SeasonalEvent, SeasonalEventMerchant } from '../../core/types/SeasonalEvent';
import { EventBattle } from '../../types/Battle';

import { getSeasonalMerchantCustomTrackingId } from './getSeasonalMerchantCustomTrackingId';

export const getSeasonalMerchantsWithCustomTrackingId = async (
  event: SeasonalEvent,
  battle: EventBattle,
  articleUrl: string,
  site: Site,
  area: string,
): Promise<SeasonalEventMerchant[]> => {
  const trackedMerchantsPromises = event.merchants.map(async (merchant) => {
    let customTrackingId: string;
    try {
      customTrackingId = await getSeasonalMerchantCustomTrackingId(
        articleUrl,
        site,
        merchant,
        area,
        battle,
      );
    } catch (_) {
      return merchant;
    }

    return {
      ...merchant,
      customTrackingId,
    };
  });

  return trackedMerchantsPromises && Promise.all(trackedMerchantsPromises);
};
