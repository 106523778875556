export enum BlockAlignmentClass {
  LEFT = 'left-alignment',
  CENTERED = 'centered-alignment',
}

export const getBlockAlignmentClass = (
  platform?: string,
  blockLayout?: string,
  blockType?: string,
): BlockAlignmentClass => {
  if (platform === 'progressive') {
    if (blockType === 'buying-guide') {
      return BlockAlignmentClass.LEFT;
    }

    switch (blockLayout) {
      case 'horizontal':
        return BlockAlignmentClass.LEFT;
      case 'hero':
      case 'versus':
      default:
        return BlockAlignmentClass.CENTERED;
    }
  }

  return BlockAlignmentClass.LEFT;
};
